body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9fafc;
  color: #595354;
}

.header {
  background-color: #ffffff;
  padding: 10px 40px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
}

.header > .logo-container {
  display: flex;
  align-items: center;
}

.header > .logo-container > .logo-img {
  width: 60px;
  height: 60px;
  margin-right: 15px;
}

.header > .logo-container > .logo-text {
  font-size: 26px;
  font-weight: 700;
}

.header > .logo-container > .logo-text > .logo-highlight {
  color: #65a9e5;
}

.content-container {
  width: 100%;
  height: calc(100vh - 89px);
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.active-users-panel {
  width: 300px;
  height: 100%;
  border-right: 1px solid #cddfe7;
}

.panel-title {
  margin: 10px 0 0 0;
  padding-left: 30px;
  font-weight: 500;
  font-size: 18px;
  border-bottom: 1px solid #cddfe7;
  padding-bottom: 10px;
}

.active-user {
  padding: 10px 30px;
  border-bottom: 1px solid #cddfe7;
  cursor: pointer;
  user-select: none;
}

.active-user:hover {
  background-color: #e8e9eb;
  transition: background-color 0.5s ease;
}

.active-user--selected {
  background-color: #fff;
  border-right: 5px solid #65a9e5;
  font-weight: 500;
  transition: all 0.5s ease;
}

.video-chat-container {
  padding: 0 20px;
  flex: 1;
  position: relative;
}

.talk-info {
  font-weight: 500;
  font-size: 21px;
}

.remote-video {
  border: 1px solid #cddfe7;
  width: 100%;
  height: 100%;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
}

.local-video {
  position: absolute;
  border: 1px solid #cddfe7;
  bottom: 60px;
  right: 40px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  width: 150px;
}

.controlsContainer{
  position: absolute;
  bottom: 0;
  align-items: center;
  justify-content: space-around;
  height: 3rem;
  /* height:50px; */
  z-index: 999;
  /* width: 100vw; */
  background-color: rgba(0,0,0,0.1);
}

.iconContainer{
  padding: 10px;
  border-radius: 50%;
  border: 2px solid var(--primary-color);
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
  background-color: var(--primary-color-hover);
  box-shadow: var(--lift-shadow);
  transition: background-color .2s cubic-bezier(.4,0,.2,1);
}

.iconContainer:hover{
  background-color: rgba(var(--color-background),0.2);
}

.iconContainer img{
  /* width: 100%; */
  width: 3rem; /*63px;*/
  height: auto;
}