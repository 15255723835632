
.tr-active {
    background-color: rgba(0, 157, 255, 0.07);
}


.noDropdownCaret.dropdown-toggle::after {
	content: none;
}

.noDropdownCaret[type="button"]:focus,
.noDropdownCaret[type="button"]:active {
	outline: none !important;
	box-shadow: none;
	outline-color: transparent;
	outline-style: none;
	color: black;
	background-color: transparent;
}
.noDropdownCaret {
	background-color: transparent;
	color: black;
	border: none;
	padding: 0px 5px;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	outline: none !important;
	outline-color: transparent;
	outline-style: none;
}


.kebab-vertical {
    transform: rotate(90deg);
}

button.linkButton.active {
	text-decoration: underline;
}

.resource-container {
	height: 100%!important;
}
@media print {
	.resource-container {
		display: none;
	}
}

.img-resource {
	width: 100%;
	height: 100%;
	margin: auto;
	object-fit: contain;
}

/* hack but works */
.react-pdf__Page__textContent {
	display: none;
}

.tree-node {
    background-color: transparent;
}

.preview-highlighted,
.tree-node.active {
    /* background-color: rgb(221, 247, 255); */
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: 1px dashed;
    /* border-style: hidden dashed hidden dashed; */
    /* border-width: 1px; */
}

.tree-node-selected-partially {
    background-color: yellow;
}
.tree-node-selected-fully {
    background-color: lightgreen;
}

.tree-node-highlighted-block,
.tree-node-highlighted-leaf {
	background-color: lightblue;
}

.clickable {
	cursor: pointer;
}

.full-width {
	width: 100%!important;
}

