.slide-sidebar-container {
    /* min-height: calc(100vh - 54px); */
    background-color: rgba(211, 211, 211, 0.35);
    /* border: 5px solid red  */
    transition: background-color 0.7s;
}

.slide-sidebar-container-close {
    background-color: transparent;
}

.slide-sidebar {
    width: 200px;
    opacity: 1;
    /* padding-top: 225px; */
    transition: width 1s, opacity 1s, transform 1s;
    overflow-y: auto;
}

.slide-sidebar-close {
    width: 0px!important;
    opacity: 0;
    transform: translate(-50px, 0);
}

.instruction-content-container {
    padding: 0px;
    /* height: calc(100vh - 88px); */
    /* min-height: calc(100vh - 88px); */

}

.slide-sidebar-icon {
    z-index: 101;
}

.prerender-resource-container {
    position: fixed;
    bottom: 0px;
    right: 0px;
    width: 50px;
    height: 50px;
}

.react-pdf__Page__textContent span {
	opacity: 0.1;
}

.react-pdf__Page__textContent span::selection {
	background-color: blue;
}

.react-pdf__Document {
	line-height: initial;
}
