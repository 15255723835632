.files input {
    outline: 2px dashed #92b0b3;
    outline-offset: -10px;
    -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
    transition: outline-offset .15s ease-in-out, background-color .15s linear;
    /* padding: 120px 0px 85px 35%; */
    /* padding: 120px 0px 85px 35%; */
    padding: 50px 0px 50px 20px;
    text-align: center !important;
    margin: 0;
    width: 100% !important;
}
.files input.form-control {
    height: initial!important;
}
.files input:focus{
    outline: 2px dashed #92b0b3;
    outline-offset: -10px;
    -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
    transition: outline-offset .15s ease-in-out, background-color .15s linear;
    border:1px solid #92b0b3;
 }
.files{ position:relative}
.color input:hover{ background-color:#b6c1ff94;}
