.dropdown-menu {
    right: 0;
    left: auto;
}


.noDropdownCaret.dropdown-toggle::after {
	content: none;
}

.noDropdownCaret[type="button"]:focus,
.noDropdownCaret[type="button"]:active {
	outline: none !important;
	box-shadow: none;
	outline-color: transparent;
	outline-style: none;
	color: black;
	background-color: transparent;
}
.noDropdownCaret {
	background-color: transparent;
	color: black;
	border: none;
	padding: 0px 5px;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	outline: none !important;
	outline-color: transparent;
	outline-style: none;
}

.full-width {
	width: 100%!important;
}

.full-height {
	height: 100%!important;
}

.full-width-vw {
	width: 100vw!important;
}

.full-height-vh {
	height: 100vh!important;
}

.treeview-offset {
	margin-left: 1.25rem;
}

button.linkButton {
	background-color: transparent;
	color: #007bff;
	border: none;
	padding: 0px 0px;
	text-align: left;
	text-decoration: none;
	cursor: pointer;
}

.btn-icon[type="button"]:focus,
.btn-icon[type="button"]:active,
.linkButton[type="button"]:focus,
.linkButton[type="button"]:active {
	outline: none !important;
}

button.btn-icon {
	background-color: transparent;
	color: black;
	border: none;
	padding: 0px 0px;
	text-align: left;
	text-decoration: none;
	cursor: pointer;
	outline: none !important;
	box-shadow:none;
}

.no-borders {
	border: 0px;
}

.tree-node {
	margin-top: 0.25rem;
	border-bottom: 1px solid rgba(0,0,0, 0.1);
}