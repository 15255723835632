.striped-rows>.striped-row:nth-of-type(odd) {  
    background-color: rgb(242, 242, 242 );
}

.striped-rows>.striped-row:nth-of-type(even) {  
    background-color: rgba(255,255,255,0);
}

.red-font {
    color: red;
    background-color: rgba(255,255,255,0.60);
}

.text-small {
    font-size: 0.8rem;
}