body {
    font: 14pt 'Alike', sans-serif;
}

#note {
    font-size: 164px;
}

.droptarget {
    background-color: #348781
}

div.confident {
    color: black;
}

div.vague {
    color: lightgrey;
}

#note {
    display: inline-block;
    height: 180px;
    text-align: left;
}

#detector {
    width: 300px;
    height: 300px;
    border: 4px solid gray;
    border-radius: 8px;
    text-align: center;
    padding-top: 10px;
}

#output {
    width: 300px;
    height: 42px;
}

#flat {
    display: none;
}

#sharp {
    display: none;
}

.flat #flat {
    display: inline;
}

.sharp #sharp {
    display: inline;
}